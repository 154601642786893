<i18n>
  {
    "es": {
      "PHONE_PLACEHOLDER": "Teléfono*"
    },
    "en": {
      "PHONE_PLACEHOLDER": "Phone*"
    },
    "fr": {
      "PHONE_PLACEHOLDER": "Téléphone*"
    },
    "de": {
      "PHONE_PLACEHOLDER": "Telefon*"
    },
    "it": {
      "PHONE_PLACEHOLDER": "Telefono*"
    },
    "pt": {
      "PHONE_PLACEHOLDER": "Telefone*"
    }
  }
</i18n>

<template>
  <div
    class="flex h-full w-full items-center justify-between space-x-2.5 text-sm text-neutral-black-2"
  >
    <div
      class="relative h-14 flex items-center space-x-3 self-stretch rounded border border-neutral-light-grey-1 px-3"
    >
      <img
        class=""
        :src="`/svg/flags/${country.toLowerCase()}.svg`"
        width="24"
        height="24"
        alt=""
      />
      <select
        class="block h-full w-5 grow-0 appearance-none overflow-hidden bg-center bg-no-repeat bg-[url('@/assets/svg/icons/grey/arrow-down.svg')] pl-6 outline-none"
        v-model="prefix"
        @change="change"
        id="country-prefix"
        name="country-prefix"
      >
        <option
          v-for="prefix in prefixes"
          :key="prefix.code"
          :id="prefix.country"
          :value="prefix.code"
        >
          {{ prefix.name }}
        </option>
      </select>
    </div>
    <LabelAnimationInput
      divClass="flex-1 h-full"
      :valid="isValid"
      required
      type="telephone"
      :id="id ?? 'telephone'"
      :name="name ?? 'telephone'"
      :placeholder="t('PHONE_PLACEHOLDER')"
      v-model="telephone"
      :autoFocus="focus"
    />
  </div>
</template>

<script setup>
const { AsYouType, parsePhoneNumber } = await import('libphonenumber-js')

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: false,
  },
  id: {
    type: String,
    required: false,
  },
  focus: {
    type: Boolean,
    required: false,
  },
})

const { t } = useI18n({
  useScope: 'local',
})

const emit = defineEmits(['update:modelValue', 'valid'])

const route = useRoute()
const { locale } = useI18n()

const telephone = ref()
const phoneNumber = ref()
const prefix = ref()

const prefixes = [
  { code: '+49', country: 'DE', name: 'Alemania (+49)' },
  { code: '+43', country: 'AT', name: 'Austria (+43)' },
  { code: '+32', country: 'BE', name: 'Bélgica (+32)' },
  { code: '+385', country: 'HR', name: 'Croacia (+385)' },
  { code: '+45', country: 'DK', name: 'Dinamarca (+45)' },
  { code: '+421', country: 'SK', name: 'Eslovaquia (+421)' },
  { code: '+386', country: 'SI', name: 'Eslovenia (+386)' },
  { code: '+34', country: 'ES', name: 'España (+34)' },
  { code: '+372', country: 'EE', name: 'Estonia (+372)' },
  { code: '+358', country: 'FI', name: 'Finlandia (+358)' },
  { code: '+33', country: 'FR', name: 'Francia (+33)' },
  { code: '+36', country: 'HU', name: 'Hungría (+36)' },
  { code: '+353', country: 'IE', name: 'Irlanda (+353)' },
  { code: '+39', country: 'IT', name: 'Italia (+39)' },
  { code: '+371', country: 'LV', name: 'Letonia (+371)' },
  { code: '+370', country: 'LT', name: 'Lituania (+370)' },
  { code: '+352', country: 'LU', name: 'Luxemburgo (+352)' },
  { code: '+47', country: 'NO', name: 'Noruega (+47)' },
  { code: '+31', country: 'NL', name: 'Países Bajos (+31)' },
  { code: '+48', country: 'PL', name: 'Polonia (+48)' },
  { code: '+351', country: 'PT', name: 'Portugal (+351)' },
  { code: '+44', country: 'GB', name: 'Reino Unido (+44)' },
  { code: '+420', country: 'CZ', name: 'República Checa (+420)' },
  { code: '+40', country: 'RO', name: 'Rumanía (+40)' },
  { code: '+46', country: 'SE', name: 'Suecia (+46)' },
  { code: '+41', country: 'CH', name: 'Suiza (+41)' },
]

let parsedPhoneNumber

try {
  parsedPhoneNumber = parsePhoneNumber(props.modelValue)
  prefix.value = '+' + parsedPhoneNumber.countryCallingCode
  telephone.value = parsedPhoneNumber.number
} catch (error) {
  const tmp = prefixes.find(
    (element) => element.country == locale.value.toUpperCase()
  )
  prefix.value = tmp.code

  try {
    parsedPhoneNumber = parsePhoneNumber(props?.modelValue, configCountry?.code)
    telephone.value = parsedPhoneNumber.number
  } catch (error) {
    //
    telephone.value = props.modelValue
  }
}

const isValid = computed(() => {
  try {
    //
    const parsedPhone = parsePhoneNumber(telephone.value, country.value)
    return parsedPhone.isValid()
  } catch (error) {
    //
  }
  return false
})

watchEffect(() => {
  emit('valid', isValid.value)
})

const nationalNumber = computed(() => {
  try {
    const parsedPhone = parsePhoneNumber(telephone.value, country.value)
    //
    return parsedPhone.nationalNumber
  } catch (error) {}
  return ''
})

const country = computed(() => {
  //
  const tmp = prefixes.find((element) => element.code == prefix.value)
  if (tmp) {
    //
    return tmp.country
  }

  return ''
})

watch([telephone, prefix], ([tel, prefix], [prevTel, prevPrefix]) => {
  const input = prefix + nationalNumber.value
  //
  try {
    const asYouType = new AsYouType(country)
    asYouType.input(input)
    const newNumber = asYouType.getNumber()
    //
    telephone.value = newNumber.number //E164∫
    emit('update:modelValue', telephone.value)
  } catch (error) {
    emit('update:modelValue', telephone.value)
  }
})

const change = () => {}
</script>
